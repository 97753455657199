<template>
  <div
    class="modal fade"
    id="modalGoodsVendor"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Daftar Vendor</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Vendor </label>
              <Select2
                style="width: 100%"
                v-model="formVendor.id_vendor"
                :options="optionVendor"
                placeholder="Pilih Vendor"
                :settings="{ allowClear: false, templateResult: resultVendor }"
                @change="barangChange('id_vendor', $event)"
                @select="barangChange('id_vendor', $event)"
                :class="{
                  'is-invalid': formErrorVendor && formErrorVendor.id_vendor,
                }"
              />
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Harga </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-model="formVendor.harga"
                  v-money="configMoney"
                  class="form-control text-right"
                  placeholder="Masukkan Harga"
                  @keyup="barangChange('harga', $event)"
                  :class="{
                    'is-invalid': formErrorVendor && formErrorVendor.harga,
                  }"
                />
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Satuan </label>
              <div class="input-group">
                <!-- <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    style="border-radius: 0; background: #e9ecef"
                    >/</span
                  >
                </div> -->

                <input
                  type="text"
                  class="form-control text-right"
                  placeholder="ex. 2000"
                  @keyup="barangChange('satuan_jumlah', $event)"
                  v-maska="'#########'"
                  :class="{
                    'is-invalid':
                      formErrorVendor && formErrorVendor.satuan_jumlah,
                  }"
                  v-model="formVendor.satuan_jumlah"
                />
                <input
                  type="text"
                  class="form-control"
                  placeholder="ex. m2"
                  v-model="formVendor.satuan_nama"
                  :class="{
                    'is-invalid':
                      formErrorVendor && formErrorVendor.satuan_nama,
                  }"
                  @keyup="barangChange('satuan_nama', $event)"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="saveBarang" class="btn btn-save">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Tambah Barang</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-barang">Barang </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Tambah
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="row mt-3">
              <div class="col-12">
                <div class="title">Tambah Barang</div>
                <div class="sub-title">Tambah informasi yang diperlukan</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12 col-sm-6">
                <!-- <div class="form-group">
                  <label>Kode Barang :</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Kode Barang"
                    v-model="formData.kode"
                    :class="{ 'is-invalid': formError && formError.kode }"
                    @keyup="formChange('kode')"
                  />
                  <div class="form-error" v-if="formError && formError.kode">
                    {{ formError.kode }}
                  </div>
                </div> -->
                <div class="form-group">
                  <label>Kategori :</label>
                  <Select2
                    v-model="formData.kategori"
                    :class="{ 'is-invalid': formError && formError.kategori }"
                    :options="optionCategory"
                    placeholder="Pilih Kategori"
                    @change="formChange('kategori', $event)"
                    @select="formChange('kategori', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.kategori"
                  >
                    {{ formError.kategori }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Jenis :</label>
                  <Select2
                    v-model="formData.jenis"
                    :class="{ 'is-invalid': formError && formError.jenis }"
                    :options="optionType"
                    placeholder="Pilih Jenis Barang"
                    @change="formChange('jenis', $event)"
                    @select="formChange('jenis', $event)"
                  />
                  <div class="form-error" v-if="formError && formError.jenis">
                    {{ formError.jenis }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="">Harga Pagu :</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Masukkan Harga Pagu"
                      @select="formChange('pagu', $event)"
                      :class="{ 'is-invalid': formError && formError.pagu }"
                      v-money="configMoney"
                      v-model="formData.pagu"
                    />
                    <div class="input-group-append">
                      <Select2
                        v-model="formData.satuan"
                        :class="{ 'is-invalid': formError && formError.satuan }"
                        :options="optionUnit"
                        placeholder="Pilih Satuan"
                        @change="formChange('satuan', $event)"
                        @select="formChange('satuan', $event)"
                      />
                    </div>
                  </div>
                  <div
                    class="form-error"
                    v-if="formError && (formError.satuan || formError.pagu)"
                  >
                    {{ formError.pagu ? formError.pagu : formError.satuan }}
                  </div>
                  <input
                    v-if="formData.satuan == 'new'"
                    type="text"
                    class="form-control mt-3"
                    placeholder="Masukkan Satuan"
                    v-model="formData.nama_satuan"
                    :class="{
                      'is-invalid': formError && formError.nama_satuan,
                    }"
                    @keyup="formChange('nama_satuan')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.nama_satuan"
                  >
                    {{ formError.nama_satuan }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Akun Barang :</label>
                  <Select2
                    v-model="formData.akun_barang"
                    :class="{
                      'is-invalid': formError && formError.akun_barang,
                    }"
                    :settings="{ templateResult: formatState }"
                    :options="optionAkunGoods"
                    placeholder="Pilih Akun Barang"
                    @change="formChange('akun_barang', $event)"
                    @select="formChange('akun_barang', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.akun_barang"
                  >
                    {{ formError.akun_barang }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Akun Beban Sewa :</label>
                  <Select2
                    v-model="formData.akun_sewa"
                    :class="{
                      'is-invalid': formError && formError.akun_sewa,
                    }"
                    :settings="{ templateResult: formatState }"
                    :options="optionAkunRental"
                    placeholder="Pilih Akun Beban Sewa"
                    @change="formChange('akun_sewa', $event)"
                    @select="formChange('akun_sewa', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.akun_swa"
                  >
                    {{ formError.akun_swa }}
                  </div>
                </div>
              </div>

              <!-- <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label>Stok Awal :</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Stok awal barang"
                    v-model="formData.stock_awal"
                    v-maska="'#################'"
                    :class="{ 'is-invalid': formError && formError.stock_awal }"
                    @keyup="formChange('stock_awal')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.stock_awal"
                  >
                    {{ formError.stock_awal }}
                  </div>
                </div>
              </div> -->

              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label>Nama Barang :</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Nama Barang"
                    v-model="formData.nama"
                    :class="{ 'is-invalid': formError && formError.nama }"
                    @keyup="formChange('nama')"
                  />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Departemen :</label>
                  <Select2
                    v-model="formData.id_divisi"
                    :class="{ 'is-invalid': formError && formError.id_divisi }"
                    :options="optionDepartement"
                    placeholder="Pilih Departemen"
                    @change="formChange('id_divisi', $event)"
                    @select="formChange('id_divisi', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_divisi"
                  >
                    {{ formError.id_divisi }}
                  </div>
                </div>
                <div class="form-group">
                  <label class="">Keterangan :</label>
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Keterangan"
                    v-model="formData.keterangan"
                  />
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-6">
                <div class="title">Daftar Vendor</div>
                <div class="sub-title">
                  Daftar vendor yang menyediakan barang
                </div>
              </div>

              <div class="col-6 text-right">
                <button
                  class="btn-add btn"
                  type="button"
                  @click="showGoodsVendor"
                >
                  Tambah
                </button>
              </div>
            </div>
            <hr />
            <div class="">
              <table
                class="table table-bordered table-striped mt-2"
                id="tableListVendor"
              >
                <thead>
                  <tr>
                    <th style="width: 20%">Vendor</th>
                    <th style="width: 15%">Harga</th>
                    <th style="width: 20%">Satuan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, index) in listVendor" :key="index">
                    <td>
                      {{ value.nama_vendor }}
                    </td>
                    <td class="text-right">{{ formatMoney(value.harga) }}</td>
                    <td class="position-relative">
                      {{ `${value.satuan_jumlah} ${value.satuan_nama}` }}
                      <div
                        style="
                          position: absolute;
                          right: -30px;
                          top: 50%;
                          transform: translateY(-50%);
                        "
                      >
                        <img
                          src="/img/avatar/icon-delete.svg"
                          @click="deleteBarang(index)"
                          class="btn-add-row ml-1"
                          style="cursor: pointer; width: 20px"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import $ from "jquery";
// import Datepicker from "vue3-date-time-picker";
// import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { checkRules, cksClient, showAlert } from "../../../../helper";
import { get_KategoriBarangList } from "../../../../actions/barang/kategori";
import { post_BarangSave } from "../../../../actions/barang";
import { get_ListDivisi, get_SatuanList } from "../../../../actions/master";
import { maska } from "maska";
import { VMoney } from "v-money";
import { get_ListVendor } from "../../../../actions/vendor";
import { get_AkunList } from "../../../../actions/akun_perkiraan";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // Datepicker,
    // TableLite
  },

  directives: {
    maska,
    money: VMoney,
  },

  data() {
    return {
      optionUnit: [],
      optionCategory: [],
      optionVendor: [],
      optionAkunGoods: [],
      optionAkunRental: [],
      optionDepartement: [],
      optionType: [
        { id: "perlengkapan", text: "Perlengkapan" },
        { id: "peralatan", text: "Peralatan" },
        // { id: "persediaan", text: "Persediaan" },
      ],
      id_company: cksClient().get("_account").id_company,
      accessDepartement: cksClient().get("_account").akses_departement,
      //FormData
      isSubmit: false,
      formData: {
        kode: "",
        nama: "",
        nama_satuan: "",
        stock_awal: "",
        satuan: "",
        id_divisi: "",
        keterangan: "",
        kategori: "",
        jenis: "",
        status: "aktif",
        barcode: "",
        pagu: "",
        id_company: cksClient().get("_account").id_company,
        akun_barang: "",
        akun_sewa: "",
      },
      rules: {
        kode: {
          required: false,
        },
        nama: {
          required: true,
        },
        satuan: {
          required: true,
        },
        stock_awal: {
          required: false,
        },
        nama_satuan: {
          required: false,
        },
        kategori: {
          required: true,
        },
        pagu: {
          required: true,
          min: 1,
          msg_min: "Inputan ini tidak boleh nol",
        },
        jenis: {
          required: true,
        },
        keterangan: {
          required: false,
        },
        akun_barang: {
          required: true,
        },
        akun_sewa: {
          required: false,
        },
      },
      formError: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      //vendor barang
      dataTableVendor: "",
      listVendor: [],
      formVendor: {
        id_vendor: "",
        nama_vendor: "",
        harga: "",
        satuan_jumlah: "",
        satuan_nama: "",
      },
      formErrorVendor: [],
      rulesVendor: {
        id_vendor: {
          required: true,
        },
        nama_vendor: {
          required: true,
        },
        harga: {
          required: true,
        },
        satuan_jumlah: {
          required: true,
        },
        satuan_nama: {
          required: true,
        },
      },
    };
  },

  created() {
    if (this.accessDepartement) {
      var data = this.accessDepartement.split("|");
      this.formData.id_divisi = data[0];
    }
    this.getSatuan();
    this.getCategory();
    this.getVendor();
    this.getAkun();
    this.getAkunSewa();
    this.getDivisi();
  },

  mounted() {
    this.setTableVendor();
  },

  beforeUnmount() {
    if (this.dataTableVendor) {
      this.dataTableVendor.destroy();
      this.dataTableVendor = "";
    }
  },

  methods: {
    showGoodsVendor() {
      this.formVendor.id_vendor = "";
      this.formVendor.harga = "";
      this.formVendor.satuan_jumlah = "";
      this.formVendor.satuan_nama = "";
      $("#modalGoodsVendor").modal("show");
    },

    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        var { list } = res;
        this.optionDepartement = list;
      });
    },

    getAkun() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: "3",
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          this.optionAkunGoods = data;
        }
      );
    },

    getAkunSewa() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: "13,14,15",
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          this.optionAkunRental = data;
        }
      );
    },

    setTableVendor() {
      if (this.dataTableVendor) {
        this.dataTableVendor.destroy();
      }
      setTimeout(() => {
        this.dataTableVendor = $("#tableListVendor").DataTable({
          info: false,
          order: [],
        });
      }, 500);
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-4" : state.sub_level == 1 ? "pl-3" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    getVendor() {
      get_ListVendor(
        {
          id_company: this.id_company,
        },
        (res) => {
          var list = res.list;
          this.optionVendor = [];
          for (const i in list) {
            if (Object.hasOwnProperty.call(list, i)) {
              const element = list[i];
              this.optionVendor.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    //MASTER
    getSatuan() {
      get_SatuanList((res) => {
        var { list } = res;
        this.optionUnit = [
          {
            id: "new",
            text: "Satuan Baru",
          },
        ];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionUnit.push({
              id: element.id,
              text: element.nama,
            });
          }
        }
      });
    },
    getCategory() {
      get_KategoriBarangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.optionCategory = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionCategory.push({
                id: element.kode,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    async formChange(key, val = "") {
      if (key == "id_divisi") {
        this.formData.akun_barang = "";
        this.formData.akun_sewa = "";
        this.getAkun();
        this.getAkunSewa();
      }
      this.rules[key].changed = true;
      var check = await checkRules(this.rules, this.formData);
      if (key === "satuan" && val.id == "new") {
        this.rules.nama_satuan.required = true;
      } else if (key === "satuan") {
        this.rules.nama_satuan.required = false;
      }
      this.formError = check.error;
    },

    //barangVendor
    async barangChange(key, val) {
      if (this.rulesVendor[key]) {
        this.rulesVendor[key].changed = true;
      }
      if (key === "id_vendor") {
        this.formVendor.nama_vendor = val.text;
        // this.formBarang.id_kategori = val.kategori;
      }
      var check = await checkRules(this.rulesVendor, this.formVendor);
      this.formErrorVendor = check.error;
    },

    async saveBarang() {
      for (const key in this.rulesVendor) {
        if (Object.hasOwnProperty.call(this.rulesVendor, key)) {
          this.rulesVendor[key].changed = true;
        }
      }

      var check = await checkRules(this.rulesVendor, this.formVendor);
      this.formErrorVendor = check.error;
      if (check.success) {
        this.listVendor.push({
          id_vendor: this.formVendor.id_vendor,
          nama_vendor: this.formVendor.nama_vendor,
          harga: this.formVendor.harga.toString().split(".").join(""),
          satuan_jumlah: this.formVendor.satuan_jumlah,
          satuan_nama: this.formVendor.satuan_nama,
        });
        this.setTableVendor();
        $("#modalGoodsVendor").modal("hide");
      }
    },
    resultVendor(val) {
      var check = this.searchObjectVendor(val.id) ? true : false;
      if (!check) {
        return val.text;
      } else {
        return null;
      }
    },

    searchObjectVendor(id) {
      return this.listVendor.find((element) => element.id_vendor == id);
    },

    deleteBarang(key) {
      this.listVendor.splice(key, 1);
      this.setTableVendor();
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    //submit
    postData() {
      this.isSubmit = true;
      var data = this.formData;
      data.vendorBarang = this.listVendor;
      post_BarangSave(
        this.formData,
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "BERHASIL",
            msg: "Data barang berhasil dicatat",
            onSubmit: () => {
              this.$router.push({
                name: "DataGoods",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi Kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.kode
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
